@import "../../assets/styles/vars.scss";
.footer {
  background-color: $secondary-pink;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: $white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .social-icons {
    margin-bottom: 20px;

    a {
      background-color: rgba(255, 255, 255, 0.362);
      border-radius: 50%;
      color: #fff;
      font-size: 20px;
      margin: 0 10px;
      &:hover {
        img {
          transition: transform 0.3s ease;
          transform: translateY(-10px);
        }
      }
      img {
        width: 50px;
        height: auto;
        object-fit: cover;
        object-position: center;
        transition: transform 0.3s ease;
      }
    }
  }

  .logo {
    margin-bottom: 20px;

    img {
      width: 100px;
      height: auto;
    }
  }

  .copywrite-text {
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    .logo {
      margin-bottom: 10px;
    }

    .copywrite-text {
      font-size: 12px;
    }
  }
}
