@import "./vars.scss";
@import "./utils/input.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap");

* {
  box-sizing: border-box;
  font-family: "Noto Sans Arabic", sans-serif;
}
html {
  scroll-behavior: smooth;
}
html::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
}
html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #86898d;
}
::selection {
  color: #eee;
  background: #86898d;
}
body {
  background-color: $white;
  padding: 0;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
}
.lang {
  transition: 0.8s all ease;
}
a {
  text-decoration: none;
  color: inherit;
}
.body-container {
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
}
.en {
  direction: ltr !important;
  text-align: left !important;
}
.ar {
  direction: rtl !important;
  text-align: right !important;
}
