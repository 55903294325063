@import "../../assets/styles/vars.scss";
.product {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 10px;
  column-gap: 50px;
  .desc {
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    color: $black;
  }
  @include tablet {
    display: block;
    .product-image {
      text-align: center;
    }
  }
  @include mobile {
    .product-image {
      img {
        width: 250px !important;
      }
    }
  }
  .product-image {
    flex: 1;
    img {
      width: 400px;
      border-radius: 20px;
      box-shadow: 0 0 10px $primary-pink;
    }
  }

  .content {
    padding: 10px;
    flex: 2;
    .information {
      margin-bottom: 10px;
      border-bottom: 1px solid $secondary-pink;
      color: $success-color;
      font-size: 20px;
      text-align: center;
      .header {
        box-shadow: 0px 2px 4px #d63484;
        border-radius: 20px;
        color: $primary-pink;
        margin-bottom: 20px;
      }
      .title {
        font-weight: bold;
        width: 100%;
      }
      .price {
        width: 100%;
      }
    }

    .submit {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      flex-direction: column;
      div {
        margin-right: 10px;
        margin-bottom: 10px;

        label {
          display: block;
          font-size: 14px;
          color: $success-color;
        }

        input,
        textarea {
          //   width: 100%;
          //   padding: 5px;
          //   border: 1px solid $primary-pink;
          //   border-radius: 5px;
          //   font-size: 14px;
          width: 100%;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          transition: 0.5s ease;
          border-width: 2px;
          &:focus {
            transition: 0.5s ease;
            border-color: $success-color;
          }
        }
        textarea {
          resize: none;
          text-align: right;
          direction: rtl;
        }
      }
    }

    .product-button {
      text-align: center;
      button {
        padding: 10px 20px;
        background-color: $secondary-pink;
        color: $white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: $primary-pink;
        }
      }
    }
  }
}
