@import "../../assets/styles/vars.scss";

.product-card {
  z-index: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  margin: 15px;

  .product-img {
    height: 320px;
    width: 100%;
    object-fit: cover;
  }

  .product-details {
    text-align: center;
  }

  .product-title {
    font-size: 15px;
    color: #333;
  }

  .product-price {
    font-size: 14px;
    color: $primary-pink;
    margin-bottom: 15px;
  }

  .product-button {
    background-color: $secondary-pink;
    color: $white;
    padding: 5px 10px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: darken($color: $primary-pink, $amount: 10);
    }
  }
}
