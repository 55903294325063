@import "../../assets/styles/vars.scss";
.sidebar {
  display: flex;
  align-items: center;
  position: relative;
  &.hide {
    .bg {
      pointer-events: none; 
    }
  }
  .bg {
    z-index: 999;
    position: absolute;
    top: 65px;
    width: 250px;
    background-color: $white;
    outline: 1px solid $secondary-pink;
    border-radius: 20px;
    margin-left: 10px;
    @include mobile {
      width: 200px;
    }
  }

  button {
    z-index: 999;
    border-radius: 50%;
    background-color: inherit;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    svg {
      path {
        stroke: $white;
      }
    }
  }
}
