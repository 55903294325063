@import "../../assets/styles/vars.scss";

.empty-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  span {
    text-align: center;
    margin: 20px;
    border-radius: 10px;
    border: 2px solid $secondary-pink;
    width: 80%;
    padding: 10px 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: $secondary-pink;
  }
}
