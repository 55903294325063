@import "../../assets/styles/vars.scss";

.products {
  min-height: 100vh;
  .grid-container {
    margin: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .load-btn {
    text-align: center;
    button {
      margin: 20px 0;
      padding: 5px 20px;
      background-color: $secondary-pink;
      color: $white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $primary-pink;
      }
    }
  }
  @include screen {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @include desktop {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include tablet {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @include mobile {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
      margin: 10px;
      gap: 0;
    }
  }
}
