.dropdown-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  .dropdown-item {
    padding: 5px;
    width: 100%;
    text-align: center;
    font-size: 15px;
    &:hover {
      background-color: darken($color: white, $amount: 10);
      border-radius: 20px;
    }
  }
}
