@import "../../assets/styles/vars.scss";

.navbar {
  // height: 100%;
  z-index: 1;
  transition: 0.5s ease;
  &.scrolled {
    transition: 0.5s ease;
    background-color: $white;
    box-shadow: 0px 0px 20px $secondary-pink;
    .search-icon {
      i {
        color: $primary-pink;
      }
    }
    button {
      svg {
        path {
          stroke: $primary-pink;
        }
      }
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  width: 100%;
  .logo {
    font-family: none;
    margin: 0;
    padding: 0;
    img {
      width: 70px;
      padding: 10px;
    }
  }

  .search-icon {
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    transition: 0.2s ease;
    i {
      color: $white;
      padding: 10px;
    }
    &:hover {
      transition: 0.2s ease;
      background-color: $primary-pink;
      i {
        color: $white;
      }
    }
  }
  .search-bar input {
    width: 200px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: 0.5s ease;
    border-width: 2px;
    &:focus {
      transition: 0.5s ease;
      border-color: $primary-pink;
    }
  }

  .btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-style: none;
    background: rgb(246, 246, 246);
    cursor: pointer;
    z-index: 1;
  }

  // @include mobile {
  //   flex-direction: column-reverse;
  //   gap: 20px;
  // }
}
