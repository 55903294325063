@import "../../assets/styles/vars.scss";
.home {
  min-height: 110vh;
  background: url("../../assets/images/cover.jpg") fixed;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-position 0.4s ease;
  @include mobile {
    background-position: right;
    transition: background-position 0.4s ease;
  }
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  .cover-image {
    padding-left: 50px;
    padding-right: 100px;
    img {
      border-radius: 50%;
      width: 300px;
    }
  }
  .content {
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    .name {
      font-size: 30px;
      font-weight: bold;
    }
    .bio {
      width: 400px;
      text-align: center;
      direction: rtl;
    }
    h3 {
      font-size: 30px;
    }
  }
}
@include desktop {
  .home {
    flex-direction: column;
    justify-content: center;

    .cover-image {
      padding: 0;
      transition: padding 1.5s ease;
    }
    .content {
      text-align: center;
      padding-right: 0;
      transition: padding-right 1.5s ease;
      .bio {
        width: 300px;
      }
    }
  }
}
@include mobile {
  .home {
    background: url("../../assets/images/cover-mobile.jpg") fixed;
    background-repeat: no-repeat;
    background-size: 100%;

    * {
      font-size: 15px;
    }

    .content {
      margin-bottom: 10px;
      padding: 5px;
      .name {
        font-size: 20px !important;
      }
      .bio {
        width: 250px !important;
        text-align: center;
        direction: rtl;
      }
      h3 {
        font-size: 30px;
      }
    }

    .cover-image {
      padding-left: 0;
      padding-right: 0;
      img {
        border-radius: 50%;
        width: 200px;
      }
    }
  }
}
